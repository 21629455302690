@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;

@layer components {
    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        @apply border-blue ring-blue;
    }

    [type='text']:hover:not(:focus):not(:disabled),
    [type='email']:hover:not(:focus),
    [type='url']:hover:not(:focus),
    [type='password']:hover:not(:focus),
    [type='number']:hover:not(:focus),
    [type='date']:hover:not(:focus),
    [type='datetime-local']:hover:not(:focus),
    [type='month']:hover:not(:focus),
    [type='search']:hover:not(:focus),
    [type='tel']:hover:not(:focus),
    [type='time']:hover:not(:focus),
    [type='week']:hover:not(:focus),
    [multiple]:hover:not(:focus),
    textarea:hover:not(:focus),
    select:hover:not(:focus) {
        @apply border-zinc-400;
    }

    [type='radio'] {
        @apply border-zinc-400 text-blue;
    }

    [type='checkbox'] {
        @apply rounded border-zinc-400 text-blue;
    }

    [type='checkbox']:focus,
    [type='radio']:focus {
        @apply ring-blue;
    }

    input:disabled,
    input:hover:disabled,
    input:read-only[name='datepicker'],
    textarea:disabled,
    textarea:hover:disabled {
        @apply border-gray-70 bg-gray-10 #{!important};
    }
}

// Additional styles
@import 'additional-styles/utility-patterns';
@import 'additional-styles/range-slider';
@import 'additional-styles/toggle-switch';
@import 'additional-styles/theme';
@import 'additional-styles/sliding-pane';
//react-select
@import 'additional-styles/react-select';
//datepicker
@import 'react-datepicker/dist/react-datepicker.css';
@import 'additional-styles/react-datepicker';
//infinite-scroll
@import 'additional-styles/infinite-scroll';
//notifications
@import 'react-toastify/dist/ReactToastify.css';
//fancybox
@import 'additional-styles/fancybox.scss';
//popup
@import 'additional-styles/popup.scss';
// svg hover
@import 'additional-styles/qual-styles.scss';
//custom scroll
@import 'react-custom-scroll/dist/customScroll.css';
@import 'additional-styles/custom-scroll';

@tailwind utilities;

@layer utilities {
    .border-spacing-0 {
        border-spacing: 0;
    }

    //BMS фильтры для статусов оборудования
    .filter-red {
        filter: brightness(0) saturate(100%) invert(18%) sepia(87%) saturate(7182%) hue-rotate(358deg) brightness(112%) contrast(115%);
    }

    .filter-gray {
        filter: grayscale(100%);
    }

    // BMS цвета для статусов
    // Нет соединения
    .bms-no-connection {
        @apply bg-gray-400 hover:bg-gray-500;
    }

    // Ошибка
    .bms-error {
        @apply bg-red-500 hover:bg-red-600;
    }

    // Все в порядке
    .bms-connected {
        @apply bg-green-600 hover:bg-green-700;
    }

    //  По умолчанию
    .bms-default {
        @apply bg-white text-black hover:bg-gray-10;
    }
}
